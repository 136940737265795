import '../App.css';
import LogoAndTagline from './LogoAndTagline';
import strings from '../strings';

function Footer() {
  return (
    <div className='Footer'>
      <LogoAndTagline />
      <div className='Footer-copyright'>
        <span className='Copyright-text'>
          {strings["Copyright-text"]}
        </span>
      </div>
    </div>
  );
}

export default Footer;
