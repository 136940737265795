import '../App.css';

function LogoAndTagline() {
  return (
    <div className='Title-container'>
      <br />
      <div className='Title' >
          tillip
      </div>
      <br />
      <div className='Sub-title'>
          Honest Business Reviews
      </div>
    </div>
  );
}

export default LogoAndTagline;
