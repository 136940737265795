import React from "react";
import B1 from "../images/B1.png"
import B2 from "../images/B2.png"
import B3 from "../images/B3.png"
import B4 from "../images/B4.png"
import strings from "../strings.js"

function Businesses() {
  return (
    <div className="Page">

      <div className="Text-and-image Reverse-column-mobile">
        <div className="Texts">
          <div className="Primary-text">
            {strings["B1-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["B1-secondary-text"]}
          </div>
        </div>
        <img className="Image" src={B1} alt={"Businesses1"} />
      </div>

      <div className="Text-and-image">
        <img className="Image" src={B2} alt={"Businesses2"} />
        <div className="Texts">
          <div className="Primary-text">
            {strings["B2-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["B2-secondary-text"]}
          </div>
        </div>
      </div>

      <div className="Text-and-image Reverse-column-mobile">
        <div className="Texts">
          <div className="Primary-text">
            {strings["B3-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["B3-secondary-text"]}
          </div>
        </div>
        <img className="Image" src={B3} alt={"Businesses3"} />
      </div>

      <div className="Text-and-image">
        <img className="Image" src={B4} alt={"Businesses4"} />
        <div className="Texts">
          <div className="Primary-text">
            {strings["B4-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["B4-secondary-text"]}
          </div>
        </div>
      </div>

    </div>
  );
}

export default Businesses;