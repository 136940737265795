import React from "react";
import '../App.css';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import strings from "../strings.js";

const firebaseConfig = {
  apiKey: "AIzaSyCkazcS60xOKjptHlm-0RqCTaKIkpcIaBw",
  authDomain: "tillip-website.firebaseapp.com",
  projectId: "tillip-website",
  storageBucket: "tillip-website.appspot.com",
  messagingSenderId: "239103094817",
  appId: "1:239103094817:web:45d24998adb38ad5947360",
  measurementId: "G-9MMJNH3HJ5"
};

const firebase = initializeApp(firebaseConfig);
class SubscriptionForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      valueSubmitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleChange(event) {
    this.setState({value: event.target.value});
  }
  
  handleSubmit(event) {
    event.preventDefault();
    this.writeUserData();
    this.setState({
      valueSubmitted: true,
      value: ""
    })
  }

  writeUserData() {
    const db = getDatabase();
    const key = this.getTimeEpoch();
    const email = this.state.value;
    set(ref(db, 'emails/' + key), {
      email: email,
    });
  }

  getTimeEpoch = () => {
    return new Date().getTime().toString();                             
}
  
  render() {
    return (
      <div className="Form-and-message">
        <form className='Input-and-button' onSubmit={this.handleSubmit}>
          <div className="Text-input-background">
            <input className="Text-input" type="email" placeholder={strings["Email-sub-placeholder"]} value={this.state.value} onChange={this.handleChange} />
          </div>
          <input className="Sign-up-button" type="submit" value="Sign Up" />
        </form>
        { this.state.valueSubmitted &&
          <div className="Email-success-message hideMeAfter5Seconds">
            Email submitted Successfully!
          </div>
        }
      </div>
    );
  }
}


export default SubscriptionForm;
