import React from "react";
import {createRoot} from 'react-dom/client';
import './index.css';
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Reviewers from "./components/Reviewers";
import Businesses from "./components/Businesses";
import Subscribe from "./components/Subscribe";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Reviewers />} />
      <Route path="/reviewers" element={<Reviewers />} />
      <Route path="/businesses" element={<Businesses />} />
    </Routes>
    <Subscribe />
    <Footer />
  </Router>

);

// serviceWorker.unregister();