import '../App.css';
import LogoAndTagline from './LogoAndTagline';
import Navbar from './Navbar';

function Header() {
  return (
    <div className='Header-combined'>
        <div className='Top-header' />
        <div className='Header'>
            <LogoAndTagline />
            <Navbar />
        </div>
    </div>
  );
}

export default Header;
