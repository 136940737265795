import '../App.css';
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <div className='Navbar'>
      {/* <div className='Navlink-container'> */}
        <NavLink className="Nav-link" to="/reviewers">
          Reviewers
        </NavLink>
      {/* </div> */}
      {/* <div className='Navlink-container'> */}
        <NavLink className="Nav-link" to="/businesses">
          Businesses
        </NavLink>
      {/* </div> */}
    </div>
  );
}

export default Navbar;
