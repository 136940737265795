var strings = {
    "C1-primary-text": "Explore thousands of businesses in your area.",
    "C1-secondary-text": "Get all the information on businesses and see which ones are recommended by the community.",
    "C2-primary-text": "Honest reviews from real people.",
    "C2-secondary-text": "On tillip you must use a receipt to review businesses, so you can be sure the reviews and ratings are unbiased.",
    "C3-primary-text": "Answer questions to review businesses.",
    "C3-secondary-text": "No more 5 star ratings. Scan your receipt and answer three quick questions. You will be done in less than a minute.",
    "C4-primary-text": "Get a refund for your review.",
    "C4-secondary-text": "Once the receipt is verified, you will get the refund. Even if you shopped there only once.",

    "B1-primary-text": "Offer refunds to attract more customers.",
    "B1-secondary-text": "Offer as little as 0.5% from every purchase in exchange for reviews. You can also just be listed on tillip without giving a refund.",
    "B2-primary-text": "Only customers can review your business.",
    "B2-secondary-text": "Reviewers will need a receipt from your businesses in order to review you, so you can make sure the review is honest.",
    "B3-primary-text": "Get many reviews on your page.",
    "B3-secondary-text": "With refunds, customers are more likely to review your business compared to other platforms, which helps you get more customers and learn more about them.",
    "B4-primary-text": "See how you are doing over time.",
    "B4-secondary-text": "tillip tells you how you are doing with different metrics and at different times, so you know how to improve.",
    
    "Email-sub-placeholder": "e.g., name@example.com",
    "Copyright-text": "© 2022 by tillip",
    "Subscribe-text": "Sign Up to get notified when tillip is available",
}

export default strings;