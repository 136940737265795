import React from "react";
import C11 from "../images/C1-1.png"
import C12 from "../images/C1-2.png"
import C2 from "../images/C2.png"
import C3 from "../images/C3.png"
import C4 from "../images/C4.png"
import strings from "../strings.js"

function Reviewers() {
  return (
    <div className="Page">

      <div className="Text-and-image Reverse-column-mobile">
        <div className="Texts">
          <div className="Primary-text">
            {strings["C1-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["C1-secondary-text"]}
          </div>
        </div>
        <div className="Images">
          <img className="Image1-of-2" src={C11} alt={"Reviewers1-1"} />
          <img className="Image2-of-2" src={C12} alt={"Reviewers1-2"} />
        </div>
      </div>

      <div className="Text-and-image">
        <img className="Image" src={C2} alt={"Reviewers2"} />
        <div className="Texts">
          <div className="Primary-text">
            {strings["C2-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["C2-secondary-text"]}
          </div>
        </div>
      </div>

      <div className="Text-and-image Reverse-column-mobile">
        <div className="Texts">
          <div className="Primary-text">
            {strings["C3-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["C3-secondary-text"]}
          </div>
        </div>
        <img className="Image" src={C3} alt={"Reviewers3"} />
      </div>

      <div className="Text-and-image">
        <img className="Image" src={C4} alt={"Reviewers4"} />
        <div className="Texts">
          <div className="Primary-text">
            {strings["C4-primary-text"]}
          </div>
          <div className="Secondary-text">
            {strings["C4-secondary-text"]}
          </div>
        </div>
      </div>

    </div>
  );
}

export default Reviewers;