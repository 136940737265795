import '../App.css';
import strings from "../strings.js";
import SubscriptionForm from './SubscriptionForm';

function Subscribe() {
  return (
    <div className='Subscribe'>
      <div className='Subscribe-text'>
        {strings["Subscribe-text"]}
      </div>
      <SubscriptionForm />
    </div>
  );
}

export default Subscribe;
